/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import MultiProductCard from "./MultiProductCard";
import Typewriter from "../../CommonComponents/TypeWriting";
import { useSelector, useDispatch } from "react-redux";
import {
  loader,
  stopStreaming,
} from "../../Services/Redux/Reducers/commonSlice";
import { storeChat } from "../../Services/Redux/Reducers/chatSlice";
import { getTitle } from "../../Services/CommonServices/helper";
import { MCatProductCardProps } from "../../Services/CommonServices/propTypes";

const MultiCatTemplate = (props) => {
  const products = props.products;
  const cardLength = products?.length;
  const [isOverviewTextLoading, setIsOverviewTextLoading] = useState(false);
  const [isConclusionLoading, setIsConclusionLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [isLastProductLoaded, setIsLastProductLoaded] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.common.isLoader);
  const streamingState = useSelector((state) => state.common.stopStreaming);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [showConusionLoader, setShowConusionLoader] = useState(false);

  useEffect(() => {
    if (props.isAnswerComplete && loading) {
      if (
        props.text != null &&
        isOverviewTextLoading == true &&
        props.conclusion &&
        props.conclusion != "" &&
        isConclusionLoading == true &&
        products &&
        cardLength &&
        isProductLoading == true
      ) {
        dispatch(storeChat(true));
        dispatch(loader(false));
      }
    }
    if (
      !isOverviewTextLoading &&
      !isProductLoading &&
      !isConclusionLoading &&
      streamingState
    ) {
      dispatch(loader(true));
    }
  }, [
    props.conclusion,
    props.isAnswerComplete,
    products,
    props.text,
    isOverviewTextLoading,
    isConclusionLoading,
    loading,
    isProductLoading,
  ]);

  const categoryListing = (products, productIndex) => {
    if (products) {
      console.log("products : ", products);
      let data = {
        text: "Show me details for the " + getTitle(products) + " look.", //as per Peter's comments
        query: "Can you show me details of outfit with ID " + products.id + "?",
        type: props.responseType,
      };
      if (!props.stopClick) {
        props?.onValueChange(data);
      }
      dispatch(stopStreaming(true));
    }
  };
  //  add delay conditionally based on the size of the products, after the last product is loaded
  useEffect(() => {
    setTimeout(() => {
      if (
        products.length > 0 &&
        currentProduct === products.indexOf(products.slice(-1)[0])
      ) {
        setIsLastProductLoaded(true);
        setShowConusionLoader(true);
        setTimeout(() => {
          setShowConusionLoader(false);
        }, 2000);
      }
    }, products.indexOf(products.slice(-1)[0]) * 1000);
  }, [currentProduct]);


  return (
    <div className="multicat_template">
      <div className="row productdetails-div">
        <div className="col-12 col-lg-8">
          <div className="mcat-text">
            <Typewriter
              convoId={props.convoId}
              type="responseText"
              text={props.text}
              delay={props.delay}
              isAnswerComplete={props.isAnswerComplete}
              setLoading={setIsOverviewTextLoading}
              effects={props.effects}
              updateNextText={setIsProductsLoading}
            />
          </div>
        </div>
      </div>

      {(isProductsLoading && products.length === 0) ? loading && (
        <div style={{ margin: "10px 20px" }} className="cat-loader" />
      ) : null}

      {isOverviewTextLoading && products.length > 0 && (
        <div className="col-12">
          <div className="cards">
            {products &&
              products.map((productDetails, productIndex) => (
                <MultiProductCard
                  stopHover={props.stopClick}
                  categoryListing={() => {
                    categoryListing(productDetails, productIndex);
                  }}
                  key={productIndex}
                  product={productDetails}
                  productIndex={productIndex}
                  cardLength={cardLength}
                  convoId={props.convoId}
                  isAnswerComplete={props.isAnswerComplete}
                  effects={props.effects}
                  setProductIndex={setCurrentProduct}
                  delay={props.delay}
                />
              ))}
          </div>
          <Typewriter
            text={""}
            delay={props.delay}
            isAnswerComplete={props.isAnswerComplete}
            setLoading={setIsProductLoading}
            effects={props.effects}
            convoId={"props.convoId"}
            type="productDetail"
            productIndex={0}
            propertyKey={"name"}
            hideLoader={true}
          />
        </div>
      )}

      {isProductLoading &&
        isLastProductLoaded &&
        (showConusionLoader && loading ? (
          <div style={{ margin: "10px 20px" }} className="cat-loader" />
        ) : (
          <div className="row">
            <div className="col-lg-9 mcat-text multi-res">
              <Typewriter
                convoId={props.convoId}
                type="responseConclusion"
                text={props.conclusion}
                delay={props.delay}
                isAnswerComplete={props.isAnswerComplete}
                setLoading={setIsConclusionLoading}
                effects={props.effects}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

MultiCatTemplate.propTypes = MCatProductCardProps;

export default MultiCatTemplate;
