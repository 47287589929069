/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import DislikeModule from "./DislikeModule";
import { useLocation, useNavigate } from "react-router-dom";
import { saveAppLevelFeedBack } from "../Services/ApiServices/chatServices";
import { apiError, appLevelFeedback } from "../Services/Redux/Reducers/chatSlice";
import { appFeedBackModal, feedBackCheck } from "../Services/Redux/Reducers/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { Cookies } from 'react-cookie-consent';

const ApplicationFeedback = () => {
  const [isLike, setIsLike] = useState(false);
  const [isDislike, setIsDislike] = useState(false);
  const [isDislikeModalOpen, setIsDislikeModalOpen] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const modalFeedBack = useSelector((state) => state.common.appFeedBackModal)
  const customerId = Cookies?.get('ar_user_Id') ? Cookies.get('ar_user_Id') : "";

  const location = useLocation()

  const handleClose = () => {
    dispatch(appFeedBackModal(false))
    if (location.pathname === '/chat') {

      navigate("/");
    }
  };
  const handleLike = (status) => {
    console.log(status, 'likeState');
    updateRes(status)
    setIsLike(!isLike);
    setIsDislike(false);
  };
  const handleDislike = async (status) => {
    setIsDislike(!isDislike);
    setIsLike(false);
    setIsDislikeModalOpen(true);
  };

  const handleDislikeModalClose = () => {
    setIsDislikeModalOpen(false);
  };
  const updateRes = (status) => {
    if (status === undefined) {
      status = "Other Issues";
    }
    let params = {
      userId: customerId,
      requestDateTime: new Date().toString(),
      feedBack: status === 'like' ? '' : status,
      status: status === 'like' ? status : 'dislike',
    }
    try {
      saveAppLevelFeedBack(params, (response) => { // Chat History storing the messages      
        console.log('Feedback saved successfyully', response);
        if (response.status === 200) {
          dispatch(appLevelFeedback(response.data))
          dispatch(feedBackCheck(false))
          handleClose();
        }
      }, (err) => {
        dispatch(apiError(err.message));
      });
    }
    catch (err) {
      console.log('getting error in operation of feedback saved', err);
    }
  }

  return (
    <div>
      {modalFeedBack && (
        <div className="modal d-flex" id="myModal" role="dialog">
          <div className="modal-content feedback-content">
            <button
              name="closeButton"
              type="button"
              className="close_btn align-self-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <img
                height="14px"
                width="14px"
                src="./static/assets/Close_icon.svg"
              />
            </button>
            <div className="modal-content_inner">
              <div className="modal-body">
                <div className="headline pb-3">
                  We Want to Hear from You
                </div>
                <div className="feedback-subtext pb-3">
                  Shape the future of Ask Ralph (Beta) by providing your feedback and insights as you explore the experience.
                </div>
                <div
                  className="d-flex  justify-content-center"
                  style={{ gap: "12px" }}
                >
                  <button name="thumbsUp" className="feedback-icon">
                    <img
                      height="17.88px"
                      width="17.88px"
                      src={
                        !isLike
                          ? "./static/assets/DesktopThumbsUp.svg"
                          : "./static/assets/likeSolid.svg"
                      }
                      alt="like"
                      onClick={() => handleLike(!isLike ? "like" : "")}
                    />
                  </button>
                  <button name="thumbsDown" className="feedback-icon">
                    <img
                      height="17.88px"
                      width="17.88px"
                      src={
                        !isDislike
                          ? "./static/assets/DesktopThumbsDown.svg"
                          : "./static/assets/dislikeSolid.svg"
                      }
                      alt="dislike"
                      onClick={() => handleDislike(!isDislike ? "dislike" : "")}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isDislike && (
        <DislikeModule
          isDislikeModalOpen={isDislikeModalOpen}
          handleClose={handleDislikeModalClose}
          updateRes={updateRes}
        />
      )}
    </div>
  );
};

export default ApplicationFeedback;
