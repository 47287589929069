import React, { useState, useEffect } from 'react';
import { ProductTypeWriterProps } from '../Services/CommonServices/propTypes';
import ProductCard from '../Components/StreamingComponents/ProductCard';
import { cloneDeep } from 'lodash';

function ProductTypeWriter(props) {
    const [currentProduct, setCurrentProduct] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [displayedProducts, setDisplayedProducts] = useState([])

    useEffect(() => {
        loadSequenceProducts()
        if (props.effects === false) {
            props.setLoading(true)
        }
    }, [props.products, props.isAnswerComplete, props.effects])

    const handleValueChange = (childValue) => {
        props.onValueChange(childValue)
    };

    const loadSequenceProducts = () => {
        if (props.products) {
            const productResponseStoppedIndex = props.products.findIndex((element) => element.responseStopped);
            if (productResponseStoppedIndex >= 0) {
                const currentProducts = cloneDeep(currentProduct);
                currentProducts[productResponseStoppedIndex] = props.products[productResponseStoppedIndex];
                setCurrentProduct([...currentProducts]);
            }
            if (currentIndex < props.products.length) {
                const displayedProducts = currentProduct;
                setDisplayedProducts(displayedProducts)
                setCurrentProduct([...currentProduct, props.products[currentIndex]]);
                setCurrentIndex(currentIndex + 1);
            }
        }
    }

    const handleCardFinish = () => {
        if (currentIndex < props.products.length) {
            loadSequenceProducts()
        } else {
            if (props.isAnswerComplete && displayedProducts.length >= props.products.length - 1) {
                props.setLoading(true)
            }
        }
    }

    return (
        <div className={"product_typewriter" + props.index}>
            {currentProduct?.map((subItem, index) => {
                return (
                    <div key={`product-${subItem.id}-${index}`}>
                        <ProductCard convoId={props.convoId} product={subItem} delay={props.delay} index={index} conclusion={props.conclusion} isCategory={props.isCategory} onValueChange={handleValueChange} isStreaming={props.isStreaming} onCardFinish={handleCardFinish} effects={props.effects} shouldLoadImage={!props.isStopResponseTriggered}
                            isStopResponseTriggered={props.isStopResponseTriggered} setIsStopResponseTriggered={props.setIsStopResponseTriggered} />
                    </div>
                )
            })}
        </div>
    );
}

ProductTypeWriter.propTypes = ProductTypeWriterProps;

ProductTypeWriter.defaultProps = {
    effects: true
};

export default ProductTypeWriter;