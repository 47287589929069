/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { MultiProductCardProps } from "../../Services/CommonServices/propTypes";

function MultiProductCard({
  productIndex,
  product,
  cardLength,
  categoryListing,
  setProductIndex,
  stopHover,
}) {
  const getClassName = (index) => {
    if (index === 0) {
      return "coat1";
    }
    if (index === 1) {
      return "shirt1";
    }
    if (index === 2) {
      return "pant1";
    }
    if (index === 3) {
      return "watch1";
    }
  };

  const slotHierarchy = {
    slot0: [
      "OUTERWEAR",
      "JACKET",
      "TAILORED CLOTHING",
      "DRESSES",
      "SWEATER",
      "SHIRTS",
      "TOPS",
      "KNIT SHIRTS",
      "SWIMWEAR",
      "ONE PIECE",
      "INTIMATE",
      "SLEEPWEAR",
    ],
    slot1: [
      "TAILORED CLOTHING",
      "DRESSES",
      "SWEATER",
      "SHIRTS",
      "TOPS",
      "KNIT SHIRTS",
      "BOTTOMS",
      "SHORTS",
      "SWIMWEAR",
      "ONE PIECE",
      "JEANS",
      "PANTS",
      "SKIRT",
      "SKORTS",
      "INTIMATE",
      "SLEEPWEAR",
      "BELTS",
      "NECKWEAR",
      "HEADWEAR",
      "BAGS",
      "EYEWEAR",
      "FINE JEWELRY",
      "FASHION JEWELRY",
      "HOSIERY",
      "SCARF",
      "GLOVES",
      "SMALL LEATHERGOODS",
      "APPAREL ACCESSORIES",
      "SMALL ACCESSORIES",
    ],
    slot2: [
      "TAILORED CLOTHING",
      "SWEATER",
      "SHIRTS",
      "TOPS",
      "KNIT SHIRTS",
      "BOTTOMS",
      "SHORTS",
      "SWIMWEAR",
      "ONE PIECE",
      "JEANS",
      "PANTS",
      "SKIRT",
      "SKORTS",
      "INTIMATE",
      "SLEEPWEAR",
      "BELTS",
      "NECKWEAR",
      "HEADWEAR",
      "BAGS",
      "EYEWEAR",
      "FINE JEWELRY",
      "FASHION JEWELRY",
      "HOSIERY",
      "SCARF",
      "GLOVES",
      "SMALL LEATHERGOODS",
      "APPAREL ACCESSORIES",
      "SMALL ACCESSORIES",
    ],
    slot3: [
      "FOOTWEAR",
      "BOOTS",
      "SHOE",
      "SNEAKERS",
      "SANDALS",
      "SLIP-ONS",
      "FLATS",
      "DRESS SHOE",
      "CASUAL SHOE",
      "ESPADRILLES",
      "CLOG",
      "PUMPS",
      "BELTS",
      "NECKWEAR",
      "HEADWEAR",
      "BAGS",
      "EYEWEAR",
      "FINE JEWELRY",
      "FASHION JEWELRY",
      "HOSIERY",
      "SCARF",
      "GLOVES",
      "SMALL LEATHERGOODS",
      "APPAREL ACCESSORIES",
      "SMALL ACCESSORIES",
    ],
  };

  // empty category map to store the products from microsoft in the given order
  let categoryObjectMapArray = [];
  let onlyCats = [];

  // function to fetch images slot wise
  const fetchImage = (
    slot, //slot number
    data, //products array
    hierarchy // hierarchy as per the slot
  ) => {
    // final data to return the image for the slot
    let finaldata;

    // only set the categorymap once, at the time of first slot
    // only setting once as the data is same for all slots
    if (slot === 0) {
      data.forEach((element) => {
        // categoryObjectMap.set(element.productCategory, element);
        categoryObjectMapArray.push({
          cat: element.productCategory,
          element: element,
        });

        onlyCats.push(element.productCategory);
      });
    }

    // state of category map as per slot
    // Loop over slot hierarchy and break upon match and delete the selected from the map
    for (const category of hierarchy) {
      if (onlyCats.includes(category)) {
        finaldata = categoryObjectMapArray.find(
          (item) => item.cat === category
        );
        const prodIndex = onlyCats.findIndex((item) => item === category);
        onlyCats.splice(prodIndex, 1);
        categoryObjectMapArray.splice(prodIndex, 1)
        break;
      }
    }

    return <>{finaldata ? imageTemplate(finaldata?.element, slot) : null}</>

  };

  const imageTemplate = (productData, prodIndex) => {
    return (
      <>
        {productData != null &&
          productData.media.map((imageUrl, imgIndex) => (
            <>
              {imageUrl.type === "PrimaryImage" && (
                <img
                  src={
                    imageUrl.url.split("?fmt=webp-alpha")[0] +
                    "?$rl_5x7_pdp$&fmt=webp-alpha"
                  }
                  alt="img"
                  className={`img-fluid  ${getClassName(prodIndex)}`}
                />
              )}
            </>
          ))}
        {productData === null && <></>}
      </>
    );
  };

  console.log("product :", product);

  const words = product.name;

  useEffect(() => {
    setProductIndex(productIndex);
  }, []);

  return (
    <button
      name="looksCard"
      style={{ animationDelay: `${productIndex * 1}s` }}
      className={`box ${stopHover ? "noHover" : "effectHover"
        } col-7 col-md-4 fade-in  ${productIndex === 0 && "mcat-box-ms"} ${productIndex === cardLength - 1 && "mcat-box-me"
        }`}
      onClick={categoryListing}
    >
      <div>
        <span className="textLook">
          {"LOOK " + `${productIndex + 1}` + " OF " + cardLength}
        </span>
      </div>

      <div className="content-title_container">
        <span className="content-title">
          {words}
        </span>
      </div>

      {fetchImage(0, product.products, slotHierarchy.slot0)}

      {fetchImage(1, product.products, slotHierarchy.slot1)}

      {fetchImage(2, product.products, slotHierarchy.slot2)}

      {fetchImage(3, product.products, slotHierarchy.slot3)}

      <button name="viewLook" className="bottomTextContainer px-0">
        <span className="viewText">+ View Look</span>
      </button>
    </button>
  );
}

MultiProductCard.propTypes = MultiProductCardProps;

export default MultiProductCard;
