import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    loggedUserDetails: null,
    isLoader: false,
    sessionId: null,
    isSessionChange: false,
    userIp: "",
    isResponseStopped: false,
    userPrompt: "",
    stopStreaming: true,
    appFeedBackModal: false,
    feedBackCheck: false,
    visibleTooltip: null,
    showDeleteModal: false,
    faqFade: false,
    addChatFlag: false,
  },
  reducers: {
    loggedUserDetails: (state, action) => {
      state.loggedUserDetails = action.payload
    },
    loader: (state, action) => {
      state.isLoader = action.payload
    },
    session: (state, action) => {
      state.sessionId = action.payload
    },
    sessionChange: (state, action) => {
      state.isSessionChange = action.payload
    },
    userIp: (state, action) => {
      state.userIp = action.payload
    },
    isResponseStopped: (state, action) => {
      state.isResponseStopped = action.payload
    },
    userPrompt: (state, action) => {
      state.userPrompt = action.payload
    },
    stopStreaming: (state, action) => {
      state.stopStreaming = action.payload
    },
    appFeedBackModal: (state, action) => {
      state.appFeedBackModal = action.payload
    },
    feedBackCheck: (state, action) => {
      state.feedBackCheck = action.payload
    },
    visibleTooltip: (state, action) => {
      state.visibleTooltip = action.payload
    },
    showDeleteModal: (state, action) => {
      state.showDeleteModal = action.payload
    },
    faqFade: (state, action) => {
      state.faqFade = action.payload
    },
    addChatFlag: (state, action) => {
      state.addChatFlag = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { loggedUserDetails, loader, session, sessionChange, userIp, isResponseStopped, userPrompt, stopStreaming, appFeedBackModal, feedBackCheck, visibleTooltip, showDeleteModal, faqFade, addChatFlag } = commonSlice.actions

export default commonSlice.reducer


