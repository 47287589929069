/* eslint-disable no-unused-vars */
import React from 'react';
import { useLocation } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import FaqBeta from './Faqbeta';



function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const [setError] = useState("");
    const [setInput] = useState("")
    const [setMessage] = useState([]);
    const isSearchBoxVisible = useSelector((state) => state.chat.searchBoxVisible);
    const isVisibleTooltip = useSelector((state) => state.common.visibleTooltip);
    const isShowDeleteModel = useSelector((state) => state.common.showDeleteModal);
    const modalFlag = useSelector((state) => state.common.appFeedBackModal);
    const isFaqFade = useSelector((state) => state.common.faqFade)



    const handleClick = () => {
        window.open('https://www.ralphlauren.global/in/en/cart', '_blank', 'noopener,noreferrer')
    };



    const handleRedirect = async () => {
        navigate("/");
    };


    const onClose = () => {
        setError("")
        setMessage([]);
        setInput("");
    }

    return (
        <div className={`header ${location.pathname === "/" && "header_transparent"}`}>
            <div className="header_options d-flex aligin-items-center justify-content-between">
                <div className="header_logo">
                    {location.pathname !== "/" &&
                        <button
                            name="closeChatButton"
                            className="close-chat-btn"
                            onClick={() => { handleRedirect() }}
                        >
                            <span>Close Chat</span>
                        </button>
                    }
                </div>
                <div className="d-flex align-items-center">
                    <div className="icon-dist">
                        <img width={20} height={20} className="cart-icon" src={location.pathname === "/" ? "/static/assets/cartwhite.svg" : "/static/assets/cart.svg"} alt="cart" onClick={() => { handleClick() }} />
                    </div>
                    {isSearchBoxVisible ? (
                        <button name="faqInfoButton" disabled={(isVisibleTooltip !== null) || (isShowDeleteModel) || (modalFlag)} className={`faq-btn px-0 ${(isVisibleTooltip !== null || isShowDeleteModel || modalFlag) && 'disablefaq'} ${isFaqFade && "faq_fade"}`} data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                            <img className="hamburger-menu-icon" src={location.pathname === "/" ? "/static/assets/updated_faq.svg" : "/static/assets/chat_menu_icon.svg"} alt="menu" />
                        </button>
                    ) : <></>}
                </div>
            </div>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header d-flex justify-content-end">
                    <button name="closeButton" type="button" className="menu-close-icon mt-2 px-0" data-bs-dismiss="offcanvas" ><img src="/static/assets/close.svg" alt="close" onClick={() => onClose()} /></button>
                </div>
                <div className="sidebar-body offcanvas-body">
                    <FaqBeta />
                </div>
            </div>

        </div>
    );
}

export default Header;