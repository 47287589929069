/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DeleteModal from "./DeleteModal";
import { useNavigate } from "react-router-dom";
import { deleteChatData } from "../../Services/ApiServices/chatServices";
import { apiError, conversationList, userCheck } from "../../Services/Redux/Reducers/chatSlice";
import { loader, stopStreaming, visibleTooltip, showDeleteModal, faqFade } from "../../Services/Redux/Reducers/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { Cookies } from 'react-cookie-consent';
import { ArchiveCarouselProps } from "../../Services/CommonServices/propTypes";

function NextArrow({ className, style, onClick }) {
  return (
    <div
      className="nextAr-arrow d-none d-lg-block"
      onClick={onClick}>
      <img className="pt-1 pe-1 next-icon" src="/static/assets/NextArrow.svg" alt="next-arrow" />
    </div>

  );
}

NextArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

function PrevArrow({ className, style, onClick }) {
  return (
    <div
      className="prevAr-arrow d-none d-lg-block"
      onClick={onClick}>
      <img className="pt-1 ps-1 prev-icon" src="/static/assets/PrevArrow.svg" alt="prev-arrow" />
    </div>

  );
}

PrevArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func
};

function ArchiveCarousel(props) {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tooltipFade, setTooltipFade] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deletedCard, setDeletedCard] = useState([])
  const [singleCard, setSingleCard] = useState([])
  const tooltipRef = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isVisibleTooltip = useSelector((state) => state.common.visibleTooltip)
  const isShowDeleteModel = useSelector((state) => state.common.showDeleteModal)
  const baseSettings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const [settings, setSettings] = useState(baseSettings);
  const userId = Cookies?.get('ar_user_Id') ? Cookies.get('ar_user_Id') : "";
  const { data, inputRef, isBackFromArchive } = props

  const openTooltip = (i) => {
    dispatch(visibleTooltip(isVisibleTooltip === i ? null : i));
    dispatch(faqFade(false));
  };
  const tooltipClose = () => {
    setTooltipFade(false);
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
    dispatch(visibleTooltip(null));
    dispatch(faqFade(true));
    setSingleCard([]);
  };

  const deleteModelOpen = (card) => {
    setDeleteModalVisible(true);
    dispatch(showDeleteModal(true));
    setDeletedCard(singleCard ? singleCard : card)
    setIsOpen(false)
    dispatch(visibleTooltip(null));
  };
  const newChat = () => {
    dispatch(stopStreaming(false))
    dispatch(conversationList([]))
    dispatch(loader(false));
    inputRef.current.focus();
    setIsOpen(false);
    dispatch(visibleTooltip(null));
  }
  const handleClose = useCallback(() => {
    setDeleteModalVisible(false);
    dispatch(showDeleteModal(false));
  });

  useEffect(() => {
    const updateSettings = () => {
      const width = window.innerWidth;
      if (width > 576 && width < 991) {
        setSettings({
          ...baseSettings,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        });
      } else if (width < 576) {
        setSettings({
          ...baseSettings,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          centerMode: true,
          centerPadding: "50px",

        });
      } else {
        setSettings(baseSettings);
      }
    };
    window.addEventListener("resize", updateSettings);
    updateSettings();
    return () => {
      window.removeEventListener("resize", updateSettings);
    };
  }, []);

  useEffect(() => {
    const slickNext = document.querySelector('.nextAr-arrow');
    const slickPrev = document.querySelector('.prevAr-arrow');
    if (slickNext) {
      if (isVisibleTooltip) {
        slickNext.style.marginTop = '-25px';
      } else if (isVisibleTooltip === 0) {
        slickNext.style.marginTop = '-25px';
      }

    }
    if (slickPrev) {
      if (isVisibleTooltip) {
        slickPrev.style.marginTop = '-25px';
      } else if (isVisibleTooltip === 0) {
        slickPrev.style.marginTop = '-25px';
      }
    }
  }, [isVisibleTooltip]);

  const handleClick = (props) => {
    const { userId, sessionId } = props;
    navigate("/chathistory", { state: { userId, sessionId } });
  }

  const dateChanger = (dateString) => {
    const dateObj = new Date(dateString);
    const formattedDate = dateObj.toLocaleDateString('en-US', {
      day: '2-digit', month: '2-digit', year: 'numeric'
    });
    return formattedDate
  }

  const timeChanger = (time) => {
    const now = new Date(time);
    const formattedTime = now.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    return formattedTime;
  }

  //for deleting the chat

  const storeMessage = async (data) => {
    const { sessionId } = data
    const params = {
      userId,
      sessionId
    }
    await deleteChatData(params, (response) => { // Chat History storing the messages  

      if (response.status === 200) {
        dispatch(userCheck(true))
        props.setModalDeleteState(prev => !prev);
      }
    }, (err) => {
      dispatch(apiError(err.message));
      dispatch(loader(false));
    });
  }

  const handleDelete = async () => {
    await storeMessage(deletedCard);
    handleClose();
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div className={`archive-container slider-container container-fluid px-0 col-12 col-md-10 col-xxl-9 ${!isBackFromArchive && 'animateSearchwithCarousel smlPad'} `}
      >
        <div className="px-0"
        >
          <Slider {...settings} >
            {data.data !== undefined && data.data !== null && data.data !== "" && data.data.map((card, i) => (
              <div style={{ position: "relative" }} key={`archive-card-${card.sessionId}-${i}`}>
                <button name="archiveChat" className="border-0 bg-transparent px-0 archive-width" style={{ minWidth: "100%" }}>
                  <div className="d-grid">
                    <div
                      className="content-container"
                      style={{ position: "relative" }}
                    >
                      <div style={{ display: "flex", padding: "5px 0" }} onClick={() => handleClick(card)} >
                        <span className="iconStyle pt-1">
                          {" "}
                          <img src={"./static/assets/RLChat.svg"} alt="chat-icon" />
                        </span>
                        <span className="ms-2 textTruncate fontStyle text-start">
                          {card?.question}
                        </span>
                      </div>

                      <div>
                        <div className="d-flex justify-content-between align-items-center day-style-div">

                          <span className="dayStyle">
                            {timeChanger(card.requestDateTime)}&nbsp;:&nbsp;{dateChanger(card.requestDateTime)}
                          </span>

                          <img
                            src={"./static/assets/three-dots.svg"}
                            width="20px"
                            height="20px"
                            alt="chat-icon"
                            className="three-dots"
                            onClick={() => {
                              openTooltip(i);
                              setIsOpen(true);
                              setTooltipFade(true);
                              setSingleCard(card);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {isVisibleTooltip === i && (
                      <div className="customTooltip d-none d-sm-block">
                        <div className="d-flex justify-content-center align-items-start flex-column text-left">
                          <div className="textTooltip d-flex justify-content-center align-items-center mb-3" onClick={newChat}>
                            <img src={"./static/assets/desktopnewchat.svg"}
                              width="81px"
                              height="16px"
                              alt="new_chat"
                            />
                          </div>

                          <div
                            className="textTooltip d-flex justify-content-center align-items-center"
                            onClick={() => deleteModelOpen(card)}
                          >

                            <img src={"./static/assets/desktopdeletechat.svg"}
                              width="65px"
                              height="16px"
                              alt="delete_chat"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </button>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {isOpen && (
        <div className="archive-container modal d-flex d-sm-none" id="myModal" role="dialog">
          <div className={`modal-content ${tooltipFade ? 'tooltip-fadein' : 'tooltip-fadeout'} `}>
            <button
              name="closeButton"
              type="button"
              className="close_btn tooltip_close align-self-end"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={tooltipClose}
            >
              <img
                height="16px"
                width="16px"
                src="./static/assets/Close_icon.svg"
              />
            </button>
            <div className="modal-content_inner pt-2">
              <div className="modal-body">
                <div className="custom-xs-tooltip d-sm-none">
                  <div className="d-flex justify-content-center align-items-start flex-column text-left">
                    <div
                      className="d-flex justify-content-center align-items-center newchat_txt"
                      style={{ color: "#041E3A", cursor: "pointer" }}
                      onClick={newChat}
                    >
                      <span>
                        <img src={"./static/assets/newchat.svg"}
                          width="81px"
                          height="16px"
                          alt="new_chat"
                        />
                      </span>
                    </div>

                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ color: "#D4222B", cursor: "pointer" }}
                      onClick={deleteModelOpen}
                    >
                      <span>
                        <img src={"./static/assets/deletechat.svg"}
                          width="65px"
                          height="16px"
                          alt="delete_chat"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isShowDeleteModel && (
        <DeleteModal
          visibleModal={deleteModalVisible}
          handleClose={handleClose}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
}

ArchiveCarousel.propTypes = ArchiveCarouselProps;

export default ArchiveCarousel;
