/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { loader, isResponseStopped, userPrompt, stopStreaming, addChatFlag } from '../Services/Redux/Reducers/commonSlice';
import { apiError, conversationList, likeApiError, appLevelFeedback, showLikeDisLikeState } from '../Services/Redux/Reducers/chatSlice';
import { addchat, uniqueSessionHistory, getAppFeedBack, updateShopClicked } from "../Services/ApiServices/chatServices";
import ChatAPIService from "../Services/ChatAPIService";
import { resetSummedresponse, updateSummedResponse } from "../Services/CommonServices/helper";
import MessageHistory from "../CommonComponents/MessageHistory";
import { css } from '@emotion/react'
import ScrollToBottom from 'react-scroll-to-bottom';
import cloneDeep from 'lodash/cloneDeep'
import LikeAndDislike from "../CommonComponents/LikeAndDislike";
import { Cookies } from 'react-cookie-consent';
import { useAbortController } from "../Context/AbortControllerContext";


const ROOT_CSS = css({
  height: 600,
  width: 400
});

function Chat() {

  const { createController, abortController } = useAbortController();
  const input = useSelector((state) => state.common.userPrompt)
  const [error, setError] = useState("");
  const name = Cookies?.get('ar_user_email') ? Cookies.get('ar_user_email') : "";
  const shortName = name ? name.charAt(0).toUpperCase() : "";
  const customerId = Cookies?.get('ar_user_Id') ? Cookies.get('ar_user_Id') : "";
  const inputRef = useRef(null);
  const [isHeight, setHeight] = useState(false)
  const [isPlaceHolderVisible, setIsPlaceHolderVisible] = useState(true);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(true);
  const [sessionId, setSessionId] = useState(sessionStorage.getItem('persist_session') ? sessionStorage.getItem('persist_session') : null);
  const loading = useSelector((state) => state.common.isLoader)
  const responseStop = useSelector((state) => state.common.isResponseStopped)
  const dispatch = useDispatch();
  const streamedResponse = useRef(resetSummedresponse());
  const [isResponseComplete, setIsResponseComplete] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const conList = useSelector((state) => state.chat.conversationList);
  const isApiError = useSelector((state) => state.chat.apiError);
  const [isScreenResponsive, setIsScreenResponsive] = useState(false);
  const effectRan = useRef(false);
  const [sessionChangeStatus, setSessionChangeStatus] = useState();
  const [lastConvoId, setLastConvoId] = useState("");
  const [isStopResponseTriggered, setIsStopResponseTriggered] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const isAddChatFlag = useSelector((state) => state.common.addChatFlag)


  // used for unsubscribe the modal
  const handleFocus = () => {
    setIsPlaceHolderVisible(false);
  }

  const onHandleInputChange = (e) => {
    const inputValue = e.target.value
    dispatch(userPrompt(inputValue));
    setIsSendButtonDisabled(inputValue.trim() === '');
    if (inputValue.length > 136 || inputValue.length) {
      setHeight(true)
    }
    else {
      setHeight(false)
    }
  }

  const handleClickOutside = event => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setIsPlaceHolderVisible(input === '' || !input);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [input]);

  useEffect(() => {
    if (!effectRan.current) {
      console.log("effect applied - only on the FIRST mount");
      if (input) {
        streaming(input);
      }
    }
    if (sessionId != null) {
      storeOldMessage();
      app_feedback()
    }
    return () => effectRan.current = true;
  }, []);

  // for input expansion 
  useEffect(() => {
    inputRef.current.style.height = "0px";
    const scrollHeight = inputRef.current.scrollHeight;
    //condition to set input height

    if (isHeight === true) {
      inputRef.current.style.height = (scrollHeight) + "px";
    } else {
      inputRef.current.style.height = (scrollHeight - 10) + "px";
    }
  }, [input]);

  //iphone height fix 
  useEffect(() => {

    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if ((macosPlatforms.test(userAgent)) || (windowsPlatforms.test(userAgent)) || ((/android/.test(userAgent)) && !safari)) {
      setIsScreenResponsive(true);
    }
  }, []);



  const storeOldMessage = () => {
    const params = [customerId, sessionId];
    // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
    uniqueSessionHistory(
      params,
      (response) => {
        // Chat History storing the messages
        dispatch(conversationList(response.data.data));
        dispatch(addChatFlag(response.data.storeChat));
        dispatch(stopStreaming(false))
      },
      (err) => {
        // dispatch(apiError(err.message));
        dispatch(loader(false));
      }
    );
  };


  //For Feedback check
  const app_feedback = () => {
    const params = [customerId && customerId];
    // const params = ['shubhangichainsing.thakur@ralphlauren.com'];
    // const params = [userId, JSON.stringify(sessionId)];
    // const params = ['bhavana.tomar@ralphlauren.com','b116faa8-1ac2-4bbd-9062-05514e9ca964']
    getAppFeedBack(
      params,
      (response) => {
        // appLevel data 
        dispatch(appLevelFeedback(response.data))
      },
      (err) => {
        dispatch(apiError(err.message));
        dispatch(loader(false));
      }
    );
  };

  const handleLoader = () => {
    dispatch(loader(true))
    setIsSendButtonDisabled(true);
    setIsPlaceHolderVisible(true);
  };

  const handleStopResponse = (isTriggered) => {
    abortController()
    setApiLoading(false);
    setIsPlaceHolderVisible(true);
    dispatch(loader(false))
    dispatch(showLikeDisLikeState(false))
    if (isTriggered) {
      localStorage.setItem("stoppedResponseId", lastConvoId);
      setIsStopResponseTriggered(true);
    }

  };

  const callUpdateShopClicked = async (params) => {
    try {
      console.log('product.url1');
      await updateShopClicked(params, (response) => { // updating the shopClicked value    
        if (response.status === 200) {
          let conHis = cloneDeep(conList)
          const conIndex = conHis.findIndex((element) => element.uid === response.data.data.uid)
          conHis[conIndex] = response.data.data
          dispatch(conversationList(conHis));
        }
      }, (err) => {
      });
    }
    catch (err) {
      console.log('getting error in updating the shopClicked Value update', err);
    }
  }

  //update Shop Card Flag
  const updateShopCard = async (convoId, id, product) => {
    const params = {
      convoId,
      id
    }
    await callUpdateShopClicked(params);
    console.log('product.url', product.url);
    window.open(product.url, "_blank", "noopener,noreferrer");
  }

  const handleValueChange = (childValue) => {
    let test = false
    if (childValue.shopClicked) {
      return
    }
    let conHis = cloneDeep(conList)
    const matchObject = conHis.find((element) => element.uid === childValue.convoId)
    matchObject && matchObject?.response?.products?.map((items) => {
      if (items.id === childValue.id) {
        if (items.shopClicked) {
          test = true
        }
      }
    })

    if (childValue.type === "quickshop" && !test && isAddChatFlag) {
      const { convoId, id, product } = childValue
      updateShopCard(convoId, id, product);
      storeOldMessage()
    }

    dispatch(stopStreaming(true))

    console.log("childValue : ", childValue)
    if (childValue !== true) {

      streamedResponse.current = resetSummedresponse();
      streaming(childValue.text, childValue.query);

    } else {
      handleStopResponse();
    }
  };

  useEffect(() => {
    // for scroll struck
    if(window.innerWidth > 576) {
      const timeout = setTimeout(() => {
      let button = document.querySelector(".follow_btn")
      if (button) {
        button.click();
      }
    }, 20);
    return () => clearTimeout(timeout);
  }
  }, [loading])

  const storeMessage = (conHis) => {
    //return
    if (localStorage.getItem('storeChat') !== "false" && isAddChatFlag) {
      let convo = cloneDeep(conHis)
      let convoList = cloneDeep(conHis)
      let lastItem = convo.pop();
      const conIndex = convoList.findIndex((element) => element.uid === lastItem.uid)
      addchat(lastItem, (response) => { // Chat History storing the messages     
        convoList[conIndex]['showLikeDislike'] = false
        convoList[conIndex]['error'] = "Oops, Something went wrong"
        dispatch(conversationList(convoList));
      }, (err) => {
        dispatch(apiError(err.message));
        dispatch(loader(false));
      });
    } else {
      localStorage.setItem('storeChat', true)
    }
  }
  const streaming = async(input, query="") => {
    localStorage.setItem("stoppedResponseId", null);
    dispatch(isResponseStopped(false));
    const newController = createController();
    dispatch(likeApiError(true));
    dispatch(apiError(false));
    let sessionChange = false
    setSessionChangeStatus();
    let button = document.querySelector(".follow_btn")
    if (button) {
      button.click();
    }
    if (loading) return;
    if (input === undefined || input === '' || input === null) {
      return;
    }
    setApiLoading(true);
    handleLoader();
    streamedResponse.current = resetSummedresponse();
    setIsStreaming(true);
    let lastGeneratedId = crypto.randomUUID().slice(2)
    setLastConvoId(lastGeneratedId);
    let userParam = {
      uid: lastGeneratedId,
      requestDateTime: new Date().toString(),
      userId: customerId,
      messageId: "",
      question: input,
      response: {},
      sessionId: sessionId,
      sessionChange: sessionChange,
      showLikeDislike: false,
      error: "",
      likeStatus: "",
      feedBack: "",
    }
    let conHistory = cloneDeep(conList)
    conHistory.push(userParam)
    dispatch(userPrompt(''));
    dispatch(showLikeDisLikeState(true))
    setError("");
    dispatch(conversationList(conHistory));
    setIsResponseComplete(true);
    try {
      const response = await ChatAPIService.postRequest(query !== "" ? query : input, sessionId, name, newController.signal)
      setApiLoading(false);
      var loopRunner = true;
      if (!response?.ok || !response.body) {
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        conHis[conIndex]['response'] = {
          responseType: "Error",
          errormessage: "An error occurred. Please try again",
          error: "An error occurred. Please try again"
        }
        dispatch(conversationList(conHis));
        dispatch(isResponseStopped(true));
        storeMessage(conHis)
        loopRunner = false;
        dispatch(loader(false));
        return;
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      var lastChunk = '';
      try {
        while (loopRunner && reader) {
          const { value, done } = await reader.read();
          if (done) {
            break;
          }
          const decodedChunk =
            lastChunk + decoder.decode(value, { stream: true });
          const responseArray = decodedChunk.split('\n\n');
          lastChunk = responseArray.pop() ?? '';
          updateResponseSteam(response, responseArray, conHistory, lastGeneratedId, sessionChange);
        }
      } catch (ex) {
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        if (ex.toString() === "AbortError: BodyStreamBuffer was aborted") {
          dispatch(isResponseStopped(true));
          conHis[conIndex]['response'] = {
            responseType: "Error",
            errormessage: "Oops, Request cancelled",
            error: "Oops, Request cancelled"
          }
          dispatch(conversationList(conHis));
        } else {
          conHis[conIndex]['response'] = {
            responseType: "Error",
            errormessage: "An error occurred. Please try again",
            error: "An error occurred. Please try again"
          }
          dispatch(conversationList(conHis));
          dispatch(isResponseStopped(true));
          storeMessage(conHis)
        }
        dispatch(loader(false))
        return;
      }
    }
    catch (ex) {
      let conHis = cloneDeep(conHistory)
      const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
      conHis[conIndex]['response'] = {
        responseType: "Error",
        errormessage: "An error occurred. Please try again",
        error: `${ex}`
      }
      dispatch(conversationList(conHis));
      dispatch(isResponseStopped(true));
      storeMessage(conHis)
      dispatch(loader(false));
      return;
    }
    setIsResponseComplete(false);
  }

  const updateResponseSteam = (response, responseArray, conHistory, lastGeneratedId, sessionChange) => {
    responseArray.forEach((responsestring) => {
      const stoppedResponseId = localStorage.getItem("stoppedResponseId");
      if (stoppedResponseId && lastGeneratedId === stoppedResponseId) {
        return;
      }
      responsestring = responsestring.replace('data:', '').trim();
      if (
        responsestring === '' ||
        responsestring === undefined ||
        responsestring === null
      ) {
        return;
      }
      if (responsestring === 'DONE') {
        return;
      }
      if (responsestring === 'error') {    // NEW BLOCK TO HANDLE ERROR CHUNK
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        conHis[conIndex]['response'] = {
          responseType: "Error",
          errormessage: "An error occurred. Please try again",
          error: "An error occurred. Please try again"
        }
        dispatch(conversationList(conHis));
        dispatch(isResponseStopped(true));
        storeMessage(conHis)
        dispatch(loader(false))
        return;
      }
      try {
        const apiResponseObject =
          JSON.parse(responsestring);

        const responseObject = JSON.parse(
          apiResponseObject.response,
        );
        if (apiResponseObject.sessionId) {
          if (sessionId == null || sessionId !== apiResponseObject.sessionId) {
            setSessionId(apiResponseObject.sessionId);
            sessionStorage.setItem('persist_session', apiResponseObject.sessionId)
            sessionChange = true
            setSessionChangeStatus(true)
          }
        }
        streamedResponse.current = updateSummedResponse(
          streamedResponse.current,
          { ...responseObject, messageId: apiResponseObject.activityId },
        );
        let conHis = cloneDeep(conHistory)
        let conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId);
        conHis[conIndex]['messageId'] = streamedResponse.current.messageId
        conHis[conIndex]['response'] = streamedResponse.current
        conHis[conIndex]['sessionId'] = apiResponseObject.sessionId
        conHis[conIndex]['sessionChange'] = sessionChange
        dispatch(conversationList(conHis));
        // Microsoft needs this please do not comment
        console.log('SessionID-', apiResponseObject.sessionId)
      }
      catch (ex) {
        let conHis = cloneDeep(conHistory)
        const conIndex = conHis.findIndex((element) => element.uid === lastGeneratedId)
        conHis[conIndex]['response'] = {
          responseType: "Error",
          errormessage: "An error occurred. Please try again",
          error: "An error occurred. Please try again"
        }
        dispatch(conversationList(conHis));
        dispatch(isResponseStopped(true));
        storeMessage(conHis)
        dispatch(loader(false))
        return;
      }
    });
  };





  return (
    <div>
      <div className="d-flex flex-column">
        <div
          className={`homepage ${loading ? "homepageresize" : ""}`}
          id="pageContainer"
        >
          <div className="page-container">
            <div className="chatwindow">
              <ScrollToBottom className={ROOT_CSS} followButtonClassName="follow_btn" >
                <div className={`conversationlist chat_options`}>
                  <div className={`container container-fluid min-height px-0 px-md-3`}>
                    <div style={{ height: "80px" }}></div>
                    {
                      conList.length > 0 &&
                      <div>
                        <MessageHistory shortName={shortName} isAnswerComplete={!isResponseComplete} onValueChange={handleValueChange} effects={isStreaming} error="" isStopResponseTriggered={isStopResponseTriggered}
                          setIsStopResponseTriggered={setIsStopResponseTriggered} />
                        {!loading && isApiError === false && isAddChatFlag ?
                          <div>
                            {responseStop === false && stopStreaming &&
                              <LikeAndDislike responseId={lastConvoId} sessionChange={sessionChangeStatus} />
                            }
                          </div>
                          :
                          <div className="text-danger mt-2 mb-3">
                            {isApiError}
                          </div>
                        }
                      </div>
                    }
                    <div>
                      {apiLoading && isPlaceHolderVisible &&
                        (<div style={{ margin: "10px 20px" }} className="cat-loader" />)
                      }
                      <div className={`${streamedResponse.current.responseType === "" && 'chat_bubble_spacing'}`}></div>
                      <div className={`${loading && streamedResponse.current.responseType !== "" && 'response_div_spacing'}`}></div>
                    </div>
                  </div>
                  <div className="d-block d-md-flex container-md justify-content-end prompts px-0 help-links ps-lg-3" >
                    {error === '' && !loading ? (
                      <div id="s-propts">
                        {error === "" && (
                          <div className="footer-tags d-flex promtsFade">
                            {
                              streamedResponse.current.suggestedPrompts &&
                              streamedResponse.current.suggestedPrompts.map((suggested, i) => (
                                <button
                                  name="suggestedPrompts"
                                  className={`footer_btn ${i === 0 && 'ms-20'} ${i === 2 && 'last-footer-btn me-20'}`}
                                  key={i}
                                  onClick={() => { streaming(suggested) }}
                                  disabled={loading}
                                >
                                  {suggested}
                                </button>
                              ))
                            }
                          </div>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </ScrollToBottom>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="col-12 d-flex justify-content-center">
          <button name="stopRespondingButton" className="d-flex stop_btn" onClick={() => handleStopResponse(true)}>
            <div className="d-flex align-items-center">
              <img
                src="/static/assets/stop_icon.png"
                className="stop-icon"
                alt="stop_icon"
              />
              <div className="foundersGroteskTextRegular font_12 ms-2 stop-text">
                Stop Responding
              </div>
            </div>
          </button>
        </div>
      )}
      <div className="footer-gradient"></div>
      <div className="footer_search">
        <div className="container footer-container">
          <div className="chatbox d-flex justify-content-center align-items-center position-relative">
            <textarea
              id="textarea_id"
              className="form-control form-focus chat"
              ref={inputRef}
              value={input}
              type="text"
              placeholder={isPlaceHolderVisible ? "Send a message" : ""}
              onFocus={handleFocus}
              onChange={(e) => onHandleInputChange(e)}
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  streaming(input);
                  setHeight(false);
                  dispatch(stopStreaming(true))
                  setIsSendButtonDisabled(true)
                }
              }}
            />
            <div>
              <button
                name="submitMessage"
                className={`${isScreenResponsive ? "m-chat-arrow" : "ios-chat-arrow"} chat-arrow send-arrow-position 
                ${loading || isSendButtonDisabled ? "disabled" : "enabled"}`}
                onClick={() => {
                  dispatch(stopStreaming(true))
                  streaming(input);
                  setHeight(false);
                  setIsSendButtonDisabled(true)
                }}
                disabled={isSendButtonDisabled}
              >
                <img
                  src={
                    loading || isSendButtonDisabled
                      ? "/static/assets/arrowlong.svg"
                      : "/static/assets/bluearrow.svg"
                  }
                  height="36px"
                  alt="arrow"
                  width="36px"
                  className="arrow_long"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;